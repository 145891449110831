import { useState } from 'react'
import { TextField, Typography, Box, Dialog } from '@mui/material'

import { auth } from '../firebase'
import { sendPasswordResetEmail } from 'firebase/auth'
import { LoadingButton } from '@mui/lab'

type ForgotPasswordProps = {
  forgotPasswordDialogOpen: boolean
  setForgotPasswordDialogOpen: (open: boolean) => void
}
export const ForgotPasswordDialog = (props: ForgotPasswordProps) => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  return (
    <Dialog
      open={props.forgotPasswordDialogOpen}
      onClose={() => {
        setSuccess(false)
        setError(false)
        setLoading(false)
        setForgotPasswordEmail('')
        props.setForgotPasswordDialogOpen(false)
      }}
    >
      <Box sx={{ p: 4, minWidth: 300 }}>
        <Typography variant="h6">Reset password</Typography>
        <Typography variant="body1">
          Enter your email and we will send you an email with a link to reset your password
        </Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
          value={forgotPasswordEmail}
          onChange={(e) => setForgotPasswordEmail(e.target.value)}
        />
        <LoadingButton
          variant="contained"
          fullWidth
          loading={loading}
          onClick={async () => {
            setLoading(true)
            try {
              await sendPasswordResetEmail(auth, forgotPasswordEmail)
              setSuccess(true)
            } catch (e) {
              setError(true)
              console.error(e)
            } finally {
              setLoading(false)
            }
          }}
        >
          Send reset email
        </LoadingButton>
        {success && (
          <Typography sx={{ mt: 1 }} variant="body2" color="primary">
            Success! If this email is registered with Helio, a reset email has been sent
          </Typography>
        )}
        {error && (
          <Typography variant="body2" color="error">
            There was an error. Please contact support
          </Typography>
        )}
      </Box>
    </Dialog>
  )
}
