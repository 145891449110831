import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiPost } from '../utils'
import { QueryKey } from '../constants'

export const usePostCheckoutId = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (sessionId: string | null) => {
      const result = await apiPost('/checkout-id', {
        sessionId,
      })
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.AVAILABLE_SESSIONS] })
    },
  })
}
