import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../constants'
import { apiPost } from '../utils'

export const useImportZoomMeeting = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (meeting) => {
      const result = await apiPost('/zoom-meetings/import', meeting)
      return result
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QueryKey.ZOOM_MEETINGS] }),
  })
}
