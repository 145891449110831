import React from 'react'
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Chip,
} from '@mui/material'
import styled from '@emotion/styled'
import { AudiotrackRounded, CloudUpload, DownloadForOffline } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const BulletPoint = styled(FiberManualRecordIcon)`
  font-size: 8px;
  margin-right: 8px;
  vertical-align: middle;
`

export const GetStartedComponent = (props: { name: string }) => {
  const [hide, setHide] = React.useState(false)
  if (hide) {
    return null
  }
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h4" gutterBottom>
        Hi {props.name}
      </Typography>
      <Paper
        elevation={3}
        sx={{ padding: 3, marginTop: 2, position: 'relative', overflow: 'hidden' }}
      >
        <Typography variant="h5" gutterBottom>
          Get Started
        </Typography>
        <Typography variant="subtitle1">Capture any session. How? You have 3 options:</Typography>
        <List sx={{ maxWidth: '80%' }}>
          <ListItem sx={{ alignItems: 'flex-start', pr: 0, pl: 0 }}>
            <ListItemIcon
              sx={{
                minWidth: '40px',
                marginTop: 1,
              }}
            >
              <AudiotrackRounded color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Record New Audio"
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary" gutterBottom>
                    <BulletPoint color="primary" />
                    Use Helio Recording to capture any session, whether you are hosting online via
                    Zoom, Hangouts, Meets, or in person.
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="text.primary" gutterBottom>
                    <BulletPoint color="primary" />
                    Click "Add session" in the menu on the left, and then "Record Audio".
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="text.primary" gutterBottom>
                    <BulletPoint color="primary" />
                    It will record any audible voices, whether you are hosting online or in person.
                    N.B. If you are using headphones, it will <b>not</b> be able to record the voice
                    of the other person
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem sx={{ alignItems: 'flex-start', pr: 0, pl: 0 }}>
            <ListItemIcon
              sx={{
                minWidth: '40px',
                marginTop: 1,
              }}
            >
              <CloudUpload color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="Upload Existing Audio"
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    <BulletPoint color="primary" />
                    If you have recorded a session with your phone, laptop or a voice recorder, use
                    the Upload Audio option.
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="text.primary">
                    <BulletPoint color="primary" />
                    Click "Add session" in the menu on the left, and then "Upload Audio".
                  </Typography>
                </>
              }
            />
          </ListItem>
          <ListItem sx={{ alignItems: 'flex-start', pr: 0, pl: 0 }}>
            <ListItemIcon
              sx={{
                minWidth: '40px',
                marginTop: 1,
              }}
            >
              <DownloadForOffline color="primary" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  Import from Zoom
                  <Chip label="Coming Soon" color="secondary" size="small" sx={{ ml: 1 }} />
                </Box>
              }
              primaryTypographyProps={{ variant: 'subtitle1' }}
              secondary={
                <>
                  <Typography component="span" variant="body2" color="text.primary">
                    {' '}
                    <BulletPoint color="primary" />
                    If you are using a free Zoom account and the sessions are saved on your
                    computer, use the Upload Audio option and upload the audio (.m4a) file from your
                    computer.
                  </Typography>
                  <br />
                  <Typography component="span" variant="body2" color="text.primary">
                    {' '}
                    <BulletPoint color="primary" />
                    If you have a Zoom subscription and your sessions are stored in the cloud, press
                    "Import from Zoom," follow the steps to connect your Zoom account, and import
                    sessions directly.
                  </Typography>
                </>
              }
            />
          </ListItem>
        </List>
        <Typography variant="subtitle1" gutterBottom>
          Still have questions? Chat to us using the chat icon in the bottom right of your screen,
          or via the <Link to="/contact-us">Contact Us</Link> page.
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          sx={{ position: 'absolute', top: 16, right: 16 }}
          onClick={() => setHide(true)}
        >
          Dismiss
        </Button>
      </Paper>
    </Box>
  )
}

export default GetStartedComponent
