import { useQuery } from '@tanstack/react-query'

import { QueryKey } from '../constants'
import { PartialUser } from '../../types'
import { apiGet } from '../utils'

export type PersonWithMostRecentSession = PartialUser & { mostRecentSessionDate: Date }

export const usePeople = () => {
  return useQuery({
    queryKey: [QueryKey.PEOPLE],
    queryFn: async () => {
      const result = await apiGet<PersonWithMostRecentSession[]>('/people')
      return result
    },
  })
}
