import React, { useEffect } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import { Box, Card, CardContent, Typography, Grid, useMediaQuery, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { isProduction } from '../env'
import { usePostCheckoutSession } from '../api/mutations/postCheckoutSession'

const plans: {
  title: string
  benefits: (string | null)[]
  price: string
  priceId: string
  addedBenefit?: string
}[] = [
  {
    title: 'Basic',
    benefits: ['10 sessions/mo', 'Share with others', null, null],
    price: '$9/month',
    priceId: isProduction ? 'price_1PKhIzGd4Nf26NJW5K8N6YSH' : 'price_1PLzWjGd4Nf26NJWweckMJ2i',
  },
  {
    title: 'Standard',
    benefits: ['35 sessions/mo', 'Share with others', 'Ask AI', null],
    price: '$29/month',
    priceId: isProduction ? 'price_1PJewVGd4Nf26NJWwsmWwkGF' : 'price_1PM0u2Gd4Nf26NJWGnfHkc92',
  },
  {
    title: 'Pro',
    benefits: ['60 sessions/mo', 'Share with others', 'Ask AI', 'Priority support'],
    price: '$49/month',
    priceId: isProduction ? 'price_1PKhNCGd4Nf26NJWT9HO95J9' : 'price_1PM0unGd4Nf26NJWibghUXJb',
    addedBenefit: 'Priority support',
  },
  {
    title: 'Ultimate',
    benefits: ['115 sessions/mo', 'Share with others', 'Ask AI', 'Priority support'],
    price: '$99/month',
    priceId: isProduction ? 'price_1PKhP4Gd4Nf26NJWYneUMi9G' : 'price_1PM0vKGd4Nf26NJWy2BG0mJB',
    addedBenefit: 'Priority support',
  },
]

const PricingTable: React.FC = () => {
  const theme = useTheme()
  const checkoutPageMutation = usePostCheckoutSession()
  const [currentlyPurchasingPlanId, setCurrentlyPurchasingPlanId] = React.useState<string | null>(
    null,
  )
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (checkoutPageMutation.isSuccess && checkoutPageMutation.data.url) {
      window.location.href = checkoutPageMutation.data.url
    }
  }, [checkoutPageMutation])
  return (
    <Box sx={{ flexGrow: 1, padding: theme.spacing(2) }}>
      <Grid container spacing={2} direction={isMobile ? 'column' : 'row'}>
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card>
              <CardContent sx={{ textAlign: 'center' }}>
                <Typography variant="h5" component="div" color="primary">
                  {plan.title}
                </Typography>
                <Typography variant="h4" component="div" sx={{ marginY: 2 }}>
                  {plan.price}
                </Typography>
                {plan.benefits.map(
                  (b, i) =>
                    i === 0 && (
                      <Box
                        key={i}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          mb: 1,
                          ml: 2,
                          opacity: b ? 1 : 0,
                        }}
                      >
                        <DoneIcon color={'primary'} sx={{ mr: 1 }} />
                        <Typography variant="body1">{`  ${b}`}</Typography>
                      </Box>
                    ),
                )}

                <LoadingButton
                  disabled={checkoutPageMutation.isPending}
                  onClick={() => {
                    setCurrentlyPurchasingPlanId(plan.priceId)
                    checkoutPageMutation.mutate({ priceId: plan.priceId })
                  }}
                  loading={
                    checkoutPageMutation.isPending && currentlyPurchasingPlanId === plan.priceId
                  }
                  variant="contained"
                  color="primary"
                >
                  Subscribe
                </LoadingButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default PricingTable
