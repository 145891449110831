import * as React from 'react'
import Avatar from '@mui/material/Avatar'

interface InitialsAvatarProps {
  initials: string
  sx?: React.CSSProperties
}

const getFontSizeForInitials = (initialsLength: number): string => {
  switch (initialsLength) {
    case 1:
    case 2:
      return '2rem' // default font size for 1 or 2 letters
    case 3:
      return '1.5rem' // smaller font size for 3 letters
    case 4:
      return '1.2rem' // even smaller for 4 letters
    default:
      return '1rem' // adjust accordingly for 5 or more letters
  }
}

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({ initials, sx }) => {
  const avatarStyle = {
    ...sx,
    fontSize: getFontSizeForInitials(initials.length),
  }
  return (
    <Avatar sx={avatarStyle}>
      <span>{initials}</span>
    </Avatar>
  )
}

export default InitialsAvatar
