import { useQuery } from '@tanstack/react-query'

import { QueryKey } from '../constants'
import { apiGet } from '../utils'

export const useEntitlements = () => {
  return useQuery({
    queryKey: [QueryKey.AVAILABLE_SESSIONS],
    queryFn: async () => {
      const result = await apiGet<{
        availableSessions: number
        totalSessionsInPeriod: number
        activeEntitlement: { productId: string; periodEnd: string | null }
      }>('/entitlements')
      return result
    },
  })
}
