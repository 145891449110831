import { useMutation, useQueryClient } from '@tanstack/react-query'

import { PartialUser } from '../../types'
import { apiPost } from '../utils'
import { QueryKey } from '../constants'

export const useAddSession = () => {
  const queryClient = useQueryClient()
  return useMutation<
    { sessionId: string },
    Error,
    { filePath: string; otherPerson: PartialUser; dateTime: Date | null }
  >({
    mutationFn: async ({ filePath, otherPerson, dateTime }) => {
      const addSessionResult = await apiPost<
        { filePath: string; otherPerson: PartialUser; dateTime: Date | null },
        { sessionId: string }
      >('/sessions', {
        filePath,
        otherPerson,
        dateTime,
      })
      return { sessionId: addSessionResult.sessionId }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.AVAILABLE_SESSIONS] })
    },
  })
}
