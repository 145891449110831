import React from 'react'
import { Typography, Button, Card, CardContent, Box, Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import { PersonWithMostRecentSession } from '../api/queries/getPeople'
import { format } from 'date-fns'

const PersonCard = (props: { person: PersonWithMostRecentSession }) => {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ width: '90%', alignItems: 'flex-start', mb: 2 }}>
          {props.person.mostRecentSessionDate && (
            <Chip
              sx={{ mb: 1, fontWeight: 'bold' }}
              label={format(new Date(props.person.mostRecentSessionDate), "dd MMM ''yy, HH:mm")}
              color="secondary"
            />
          )}
          <Typography fontWeight="bold" variant="h4">
            {props.person.name}
          </Typography>
        </Box>
        <Link style={{ width: '90%' }} to={`/person/${props.person.id}`}>
          <Button style={{ width: '100%' }} variant="contained">
            View
          </Button>
        </Link>
      </CardContent>
    </Card>
  )
}

export default PersonCard
