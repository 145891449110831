import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const FullScreenLoader = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Loading...
      </Typography>
    </Box>
  )
}

export default FullScreenLoader
