export interface PartialUser {
  // id is optional on the frontend because partial user is created without it
  id?: string
  name: string
  email?: string
  // linkedUserId may be added later when the user connects their account
  linkedUserId?: string
}

export interface User {
  id: string
  email: string
  name?: string
  photoUrl?: string
  people: PartialUser[]
}

export enum MeetingProvider {
  ZOOM = 'ZOOM',
  MANUAL = 'MANUAL',
}

export enum SessionStatus {
  'TRANSCRIBING_AUDIO' = 'TRANSCRIBING_AUDIO',
  'SUMMARISING' = 'SUMMARISING',
  'COMPLETE' = 'COMPLETE',
}

export interface Session {
  id: string
  ownerId: string
  otherPersonId: string
  startTime: Date
  status: SessionStatus
  topic?: string
  provider: MeetingProvider
  providerMeetingId?: string
  audioId?: string
  transcriptId?: string
  summaryId?: string
  createdAt: Date
  updatedAt: Date
  durationMinutes?: number
  notes?: string
}

interface Sentence {
  text: string
  start: number
  end: number
}

export interface Paragraph {
  speaker: number
  sentences: Sentence[]
  start: number
  end: number
  num_words: number
}

export enum TranscriptType {
  DEEPGRAM = 'DEEPGRAM',
  USER_PROVIDED = 'USER_PROVIDED',
}
export interface Transcript {
  id: string
  rawTranscriptionResult: any
  transcriptString: string
  paragraphs: Paragraph[]
  type: TranscriptType
  language?: string
  languageConfidence?: number
}
