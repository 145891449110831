import React, { ReactNode, useEffect, useState } from 'react'
import { SupabaseClient, createClient } from '@supabase/supabase-js'
import { User as AuthUser, getAuth, onAuthStateChanged } from 'firebase/auth'
import firebaseApp from '../firebase'
import FullScreenLoader from '../components/FullScreenLoader'
import { useBootstrap } from '../api'
import { User } from '../types'
import { Box } from '@mui/material'
import { apiGet } from '../api/utils'

const SUPABASE_URL = 'https://fmdxvloeqethqhlhozkt.supabase.co'
const SUPABASE_PUBLIC_KEY =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImZtZHh2bG9lcWV0aHFobGhvemt0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4OTA0NzYsImV4cCI6MjAyODQ2NjQ3Nn0.EhDHZIEHx9iwLSYXjW67qOhFM7PCnFjkpdXbOZ09iC8'

type AuthProviderProps = {
  children: ReactNode
}

let authedClient: SupabaseClient

export const AuthContext = React.createContext<{
  user: User | null
  loading: boolean
  supabase: SupabaseClient | null
}>({
  user: null,
  loading: true,
  supabase: null,
})

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null)
  const [loading, setLoading] = useState(true)
  const [supabase, setSupabase] = useState<SupabaseClient | null>(null)
  const auth = getAuth(firebaseApp)
  const bootstrapQuery = useBootstrap(authUser?.uid)

  const bootstrapData = bootstrapQuery.isSuccess && Boolean(authUser) ? bootstrapQuery.data : null
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setAuthUser(firebaseUser)
      setLoading(false)
    })

    return unsubscribe
  }, [auth])

  useEffect(() => {
    const fetchAuthJwt = async (userId: string) => {
      const result = await apiGet<{ token: string }>(`/auth?userId=${userId}`)
      if (!authedClient) {
        authedClient = createClient(SUPABASE_URL, SUPABASE_PUBLIC_KEY, {
          global: {
            headers: {
              Authorization: `Bearer ${result.token}`,
            },
          },
        })
      }
      setSupabase(authedClient)
    }
    if (authUser) {
      fetchAuthJwt(authUser.uid)
    }
  }, [authUser])

  if (loading || bootstrapQuery.isLoading) {
    return (
      <Box sx={{ height: '100vh' }}>
        <FullScreenLoader />
      </Box>
    )
  }

  return (
    <AuthContext.Provider value={{ supabase, user: bootstrapData, loading }}>
      {children}
    </AuthContext.Provider>
  )
}
