import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Typography,
  Box,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  List,
  ListItem,
  Chip,
  Snackbar,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'

import FullScreenLoader from '../components/FullScreenLoader'
import { SessionWithTopics, useSessionsWithOtherPerson } from '../api/queries/getSessionsWithPerson'
import InitialsAvatar from '../components/InitialsAvatar'
import { formatDateStringShorter, formatDateStringShortest } from '../utils'
import { useUpdateOtherPerson } from '../api/mutations/updateOtherPerson'
import { LoadingButton } from '@mui/lab'
import { TopicChip } from '../components/TopicChip'

const Session = (props: { session: SessionWithTopics; index: number; isMobile: boolean }) => {
  return (
    <Link to={`/session/${props.session.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
      <ListItem
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(6, 1fr)',
          gap: 2,
          borderRadius: 4,
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#E7E7E7',
          },
        }}
        alignItems="flex-start"
      >
        <Typography variant="body2" sx={{ gridColumn: props.isMobile ? '1 / span 3' : '1' }}>
          {props.session.topic || `Session ${props.index + 1}`}
        </Typography>
        {!props.isMobile && (
          <Box sx={{ gridColumn: '2 / span 3', alignItems: 'center' }}>
            {props.session.topics.slice(0, 3).map((topic, index) => (
              <TopicChip key={index} topic={topic} maxWidth="25%" />
            ))}
          </Box>
        )}
        <Typography variant="body2" sx={{ gridColumn: props.isMobile ? '4 / span 2' : '5' }}>
          {props.isMobile
            ? formatDateStringShortest(props.session.startTime)
            : formatDateStringShorter(props.session.startTime)}
        </Typography>
        <Box sx={{ gridColumn: '6' }}>
          <Chip
            sx={{ height: 25 }}
            label={`${props.session.durationMinutes} mins`}
            color="secondary"
          />
        </Box>
      </ListItem>
    </Link>
  )
}

const SnackbarAction = (props: { handleClose: () => void }) => (
  <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
    <CloseIcon fontSize="small" />
  </IconButton>
)

const PersonPage = () => {
  const { otherPersonId } = useParams<{ otherPersonId: string }>()
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  // TODO: what if other user id is not valid?
  const sessionsWithOtherPersonQuery = useSessionsWithOtherPerson(otherPersonId!)
  const updatePersonMutation = useUpdateOtherPerson({
    onSuccess: () => {
      setSnackbarIsOpen(true)
    },
  })
  if (sessionsWithOtherPersonQuery.isLoading) {
    return <FullScreenLoader />
  }
  if (sessionsWithOtherPersonQuery.isError || !sessionsWithOtherPersonQuery.data) {
    return <div>Error loading person</div>
  }

  const { otherPerson, sessions } = sessionsWithOtherPersonQuery.data
  const initials = otherPerson.name
    .split(' ')
    .map((name) => name[0])
    .join('')
    .toUpperCase()
  return (
    <>
      <Box
        sx={{ p: isMobile ? 1 : 3, display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}
      >
        <Paper
          sx={{
            p: 2,
            mr: 1,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: 'fit-content',
          }}
        >
          <InitialsAvatar sx={{ height: 75, width: 75 }} initials={initials} />
          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            {otherPerson.name}
          </Typography>
          {otherPerson.email && <Typography variant="subtitle1">{otherPerson.email}</Typography>}
          <LoadingButton
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{ my: 2, minWidth: '70%' }}
            onClick={() => {
              setName(otherPerson.name)
              setEmail(otherPerson.email || '')
              setEditDialogOpen(true)
            }}
            loading={updatePersonMutation.isPending}
          >
            Edit
          </LoadingButton>
        </Paper>

        <Paper sx={{ p: 2, flex: '5', ml: isMobile ? 0 : 2, mt: isMobile ? 2 : 0 }}>
          <Typography variant="h6" gutterBottom sx={{ ml: 2, my: 1 }}>
            Sessions with {otherPerson.name}
          </Typography>
          <List
            sx={{ backgroundColor: theme.palette.background.default, borderRadius: 4, padding: 1 }}
          >
            <ListItem sx={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', gap: 2 }}>
              <Typography variant="subtitle1" sx={{ gridColumn: '1 / span 3' }}>
                Meeting
              </Typography>
              <Typography variant="subtitle1" sx={{ gridColumn: isMobile ? '4 / span 2' : '5' }}>
                Date
              </Typography>
              <Typography variant="subtitle1" sx={{ gridColumn: '6' }}>
                Duration
              </Typography>
            </ListItem>
            {sessions.map((session, index) => (
              <Session key={index} session={session} index={index} isMobile={isMobile} />
            ))}
          </List>
        </Paper>
      </Box>
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            setEditDialogOpen(false)
            updatePersonMutation.mutate({
              ...otherPerson,
              name,
              email,
            })
          }}
        >
          <DialogTitle>Edit this person</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mr: 2 }}
              autoFocus
              margin="normal"
              id="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              label="name"
              type="input"
              variant="standard"
            />
            <TextField
              margin="normal"
              id="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              label="email (optional)"
              type="input"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarIsOpen(false)}
        message="Saved changes! ✅"
        action={<SnackbarAction handleClose={() => setSnackbarIsOpen(false)} />}
      />
    </>
  )
}
export default PersonPage
