import { format } from 'date-fns'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { StorageError, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from './firebase'

export const formatDateString = (dateString: string | Date): string => {
  const date = new Date(dateString)
  return format(date, 'MMMM do, yyyy')
}

export const formatDateStringShorter = (dateString: string | Date): string => {
  const date = new Date(dateString)
  return format(date, 'MMM d, yyyy')
}

export const formatDateForFilename = (dateString: string | Date): string => {
  const date = new Date(dateString)
  return format(date, 'yyyy-MM-dd')
}

export const formatDateStringShortest = (dateString: string | Date): string => {
  const date = new Date(dateString)
  return format(date, 'MMM d')
}

export const escapeRegExp = (string: string): string => {
  // Escapes special characters for use in a regular expression
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export const useDebounce = <T extends any[]>(
  callback: (...args: T) => any,
  delay: number = 1000,
) => {
  const ref = useRef<(...args: T) => any>()

  useEffect(() => {
    ref.current = callback
  }, [callback])

  const debouncedCallback = useMemo(() => {
    const func = (...args: T) => {
      ref.current?.(...args)
    }

    return _.debounce(func, delay)
  }, [delay])

  return debouncedCallback
}

export function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(!document.hidden)

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsPageVisible(!document.hidden)
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  return isPageVisible
}

export const getFileName = (userId: string, fileName: string) => {
  const todaysDateString = new Date().toISOString().split('T')[0]
  const nowTimeString = new Date().toISOString().split('T')[1]
  return `users/${userId}/${todaysDateString}/${nowTimeString}-${fileName}`
}

export const useUploadFile = () => {
  const [progress, setProgress] = useState<number>(0)
  const [error, setError] = useState<StorageError | null>(null)
  const [filePath, setFilePath] = useState<string | null>(null)

  const uploadFile = async (blob: Blob, userId: string, name: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, getFileName(userId, name))
      const uploadTask = uploadBytesResumable(storageRef, blob)

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          setProgress(progress)
        },
        (error: StorageError) => {
          setError(error)
          reject(error)
        },
        async () => {
          setFilePath(uploadTask.snapshot.metadata.fullPath)
          resolve(uploadTask.snapshot.metadata.fullPath)
        },
      )
    })
  }

  return { progress, filePath, error, uploadFile }
}
