import { useQuery } from '@tanstack/react-query'

import { QueryKey } from '../constants'
import { PartialUser, Session } from '../../types'
import { apiGet } from '../utils'

export type SessionWithOtherUser = Session & { otherPerson?: PartialUser }

export const useSessions = () => {
  return useQuery({
    queryKey: [QueryKey.SESSIONS],
    queryFn: async () => {
      const result = await apiGet<SessionWithOtherUser[]>('/sessions')
      return result
    },
  })
}
