import React, { useCallback, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useLocation, useNavigate } from 'react-router-dom'

import { apiGet } from '../api/utils'

const ZoomCallbackHandler: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const query = new URLSearchParams(location.search)
  const code = query.get('code')

  const exchangeCodeForToken = useCallback(
    async (code: string) => {
      try {
        await apiGet(`/zoom-token?code=${code}`)
        navigate('/zoom-meetings')
      } catch (error) {
        console.error('Error exchanging Zoom code for token:', error)
      }
    },
    [navigate],
  )
  useEffect(() => {
    if (code) {
      exchangeCodeForToken(code)
    }
  }, [code, exchangeCodeForToken])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100vh' }}
    >
      <CircularProgress />
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Processing Zoom authentication...
      </Typography>
    </Box>
  )
}

export default ZoomCallbackHandler
