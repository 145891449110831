import React from 'react'
import { Document, Page, Text, View, StyleSheet, Font, Image, Link } from '@react-pdf/renderer'
import { DefaultSummaryData } from './DefaultSummaryV1Section'

import MontserratRegular from '../assets/Montserrat-Regular.ttf'
import MontserratBold from '../assets/Montserrat-Medium.ttf'
import HelioLogo from '../assets/logoPng.png'

Font.register({
  family: 'Montserrat',
  fonts: [{ src: MontserratRegular }, { src: MontserratBold, fontWeight: 'bold' }],
})

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 10,
    padding: 10,
  },
  headerLeft: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateText: {
    fontSize: 12,
    marginRight: 10,
  },
  durationPill: {
    backgroundColor: '#58BFA8',
    borderRadius: 10,
    padding: '2 8',
  },
  durationText: {
    color: 'white',
    fontSize: 10,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
    padding: 30,
    fontFamily: 'Montserrat',
  },
  heading: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    fontFamily: 'Montserrat',
  },
  section: {
    marginVertical: 5,
    marginHorizontal: 10,
    padding: 10,
  },
  subheading: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  topics: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
  },
  topic: {
    backgroundColor: '#58BFA8',
    color: 'white',
    padding: '3 8',
    borderRadius: 15,
    margin: 2,
    fontSize: 10,
  },
  quote: {
    backgroundColor: '#2C67E426',
    padding: 10,
    marginBottom: 5,
    borderRadius: 5,
  },
  actionPoint: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  checkbox: {
    width: 12,
    height: 12,
    border: '1px solid black',
    marginRight: 10,
    marginTop: 2,
  },
  actionText: {
    fontSize: 12,
    flex: 1,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 10,
    color: '#888',
  },
  footerText: {
    marginBottom: 2,
  },
  footerLogo: {
    width: 75,
    marginLeft: 5,
  },
  footerRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: '#888',
    textDecoration: 'none',
  },
})

const Summary = (props: { summary: string | string[] }) => {
  if (Array.isArray(props.summary)) {
    return (
      <>
        {props.summary.map((paragraph, index) => (
          <Text key={index} style={styles.text}>
            {paragraph}
          </Text>
        ))}
      </>
    )
  }
  return <Text style={styles.text}>{props.summary}</Text>
}

export const SummaryPdf = (props: {
  myName: string
  otherPersonName: string
  summary: DefaultSummaryData
  date: string
  duration: string
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header}>
        <Text style={styles.headerLeft}>
          {props.myName}/{props.otherPersonName}
        </Text>
        <View style={styles.headerRight}>
          <Text style={styles.dateText}>{props.date}</Text>
          {props.duration && (
            <View style={styles.durationPill}>
              <Text style={styles.durationText}>{props.duration}</Text>
            </View>
          )}
        </View>
      </View>
      <View style={styles.section}>
        <Text style={styles.heading}>Session summary</Text>

        <Text style={styles.subheading}>Key topics discussed</Text>
        <View style={styles.topics}>
          {props.summary.topics.map((topic, index) => (
            <Text key={index} style={styles.topic}>
              {topic}
            </Text>
          ))}
        </View>

        <Summary summary={props.summary.summary} />
      </View>

      <View style={styles.section}>
        <Text style={styles.subheading}>Highlighted quotes</Text>
        {props.summary.quotes.map((quote, index) => (
          <View key={index} style={styles.quote} wrap={false}>
            <Text style={styles.text}>"{quote}"</Text>
          </View>
        ))}
      </View>

      <View style={styles.section}>
        <Text style={styles.subheading}>Action points</Text>
        {props.summary.actionPoints.map((point, index) => (
          <View key={index} style={styles.actionPoint} wrap={false}>
            <View style={styles.checkbox} />
            <Text style={styles.actionText}>{point}</Text>
          </View>
        ))}
      </View>

      <View style={styles.section} wrap={false}>
        <Text style={styles.subheading}>Suggestions for consideration</Text>
        {props.summary.suggestions.map((suggestion, index) => (
          <Text key={index} style={styles.text}>
            • {suggestion}
          </Text>
        ))}
      </View>
      <View style={styles.footer}>
        <View style={styles.footerRow}>
          <Text style={styles.footerText}>created with</Text>
          <Image style={styles.footerLogo} src={HelioLogo} />
        </View>
        <Link src="https://www.helio.chat" style={styles.link}>
          <Text style={styles.footerText}>www.helio.chat</Text>
        </Link>
      </View>
    </Page>
  </Document>
)
