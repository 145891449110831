import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { usePeople } from '../api'
import PersonCard from '../components/PersonCard'
import FullScreenLoader from '../components/FullScreenLoader'
import { User } from '../types'

const PeoplePage = (props: { user: User }) => {
  const peopleQuery = usePeople()
  if (peopleQuery.isLoading) {
    return <FullScreenLoader />
  }
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        People
      </Typography>
      <Grid container spacing={2}>
        {peopleQuery.isSuccess &&
          peopleQuery.data.map((person, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <PersonCard person={person} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
export default PeoplePage
