import { useMutation } from '@tanstack/react-query'

import { User } from '../../types'
import { apiPost } from '../utils'

export const usePostUser = () =>
  useMutation({
    mutationFn: async (user: Omit<User, 'people'>) => {
      const result = await apiPost('/users', {
        user,
      })
      return result
    },
  })
