import { useMutation } from '@tanstack/react-query'
import { apiPut } from '../utils'

export const useUpdateNotes = () => {
  return useMutation({
    mutationFn: async ({ sessionId, notes }: { sessionId: string; notes: string }) => {
      if (!sessionId) {
        return
      }
      const result = await apiPut<{ notes: string }, void>(`/sessions/${sessionId}/notes`, {
        notes,
      })
      return result
    },
  })
}
