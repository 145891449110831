import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../constants'
import { apiGet } from '../utils'

export const useZoomMeetings = () => {
  return useQuery({
    queryKey: [QueryKey.ZOOM_MEETINGS],
    queryFn: async () => {
      const result = await apiGet<any[]>('/zoom-meetings')
      return result
    },
  })
}
