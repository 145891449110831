import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'

import {
  Typography,
  Paper,
  Box,
  Container,
  Chip,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import {
  BlockTypeSelect,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  toolbarPlugin,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import SaveAltIcon from '@mui/icons-material/SaveAlt'

import FullScreenLoader from '../components/FullScreenLoader'
import { useSession } from '../api'
import { DefaultSummaryV1Section } from '../components/DefaultSummaryV1Section'
import { Paragraph, SessionStatus, User } from '../types'
import {
  formatDateForFilename,
  formatDateStringShorter,
  useDebounce,
  usePageVisibility,
} from '../utils'
import { useUpdateNotes } from '../api/mutations/putNotes'
import TextHighlightButton from '../components/TextHighlightButton'
import { useQueryClient } from '@tanstack/react-query'
import { QueryKey } from '../api/constants'
import MultiStepLoader from '../components/MultiStepLoader'
import ComingSoonModal from '../components/ComingSoonModal'
import { SummaryPdf } from '../components/PdfTemplate'
import { LoadingButton } from '@mui/lab'

const ParagraphBubble: React.FC<{ paragraph: Paragraph }> = ({ paragraph }) => {
  const minutes = Math.floor(paragraph.start / 60)
  const seconds = Math.floor(paragraph.start % 60)
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 2,
        borderRadius: 2,
        padding: 2,
        backgroundColor: paragraph.speaker === 0 ? '#EEF8F6' : '#EFF4FF',
        ml: paragraph.speaker === 1 ? 5 : 0,
        mr: paragraph.speaker === 0 ? 5 : 0,
      }}
    >
      <Box sx={{ display: 'flex', mb: 1 }}>
        <Box sx={{ flexGrow: 1 }}>
          {paragraph.sentences.map((sentence, index) => (
            <Typography key={index} variant="body1">
              {sentence.text}{' '}
            </Typography>
          ))}
        </Box>
        <Box>
          <Typography variant="subtitle1" sx={{ color: '#87868B' }}>
            {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const getFileName = (otherPersonName: string, startTime: Date) => {
  const name = otherPersonName.replace(/ /g, '-')
  const dateString = formatDateForFilename(startTime)
  return `${name}-${dateString}.pdf`
}

const SessionPage = (props: { user: User }) => {
  const { sessionId } = useParams<{ sessionId: string }>()
  const [showNotes, setShowNotes] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [notes, setNotes] = useState('')
  const [pdfLoading, setPdfLoading] = useState(false)

  const editorRef = useRef<MDXEditorMethods>(null)
  const queryClient = useQueryClient()
  const sessionQuery = useSession(sessionId)
  const updateNotesMutation = useUpdateNotes()
  const isPageVisible = usePageVisibility()
  const timerIdRef = useRef<NodeJS.Timer | null>(null)
  const [isPollingEnabled, setIsPollingEnabled] = useState(true)
  const [isComingSoonVisible, setIsComingSoonVisible] = useState(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const pollingCallback = async () => {
      await queryClient.refetchQueries({ queryKey: [QueryKey.SESSION, sessionId] })
    }

    const startPolling = () => {
      timerIdRef.current = setInterval(pollingCallback, 2000)
    }

    const stopPolling = () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current)
      }
    }
    if (isPageVisible && isPollingEnabled) {
      startPolling()
    } else {
      stopPolling()
    }

    return () => {
      stopPolling()
    }
  }, [isPageVisible, isPollingEnabled, sessionId, queryClient])

  useEffect(() => {
    if (sessionQuery.data?.session.status === SessionStatus.COMPLETE) {
      setIsPollingEnabled(false)
    }
  }, [sessionQuery.data?.session.status])

  const debouncedUpdateNotes = useDebounce(() => {
    updateNotesMutation.mutate({ sessionId: sessionId || '', notes })
    setIsTyping(false)
  })

  const updateNotes = (markdown: string) => {
    setIsTyping(true)
    setNotes(markdown)
    debouncedUpdateNotes()
  }

  useEffect(() => {
    if (sessionQuery.data?.session.notes) {
      setNotes(sessionQuery.data?.session.notes)
    }
  }, [sessionQuery.data?.session.notes])

  if (sessionQuery.isLoading) {
    return <FullScreenLoader />
  }

  // TODO error handling/suspense
  if (!sessionId || sessionQuery.isError || !sessionQuery.data) {
    return <div>Error loading session</div>
  }

  const { transcriptParagraphs, summary, otherPerson, session } = sessionQuery.data

  if (session.status === SessionStatus.TRANSCRIBING_AUDIO) {
    return (
      <MultiStepLoader
        currentStep={2}
        steps={[
          { inProgress: 'Uploading file...', completed: 'Uploaded' },
          { inProgress: 'Transcribing audio...', completed: 'Transcribed' },
        ]}
        subtitle="It could take up to two minutes to upload, transcribe and summarise your session."
        subtitle2="You can close this page and find your session through the sessions page later."
        button={
          <Button component={Link} to="/add-session">
            Import a new session
          </Button>
        }
      />
    )
  }

  if (session.status === SessionStatus.SUMMARISING) {
    return (
      <MultiStepLoader
        currentStep={3}
        steps={[
          { inProgress: 'Uploading file...', completed: 'Uploaded' },
          { inProgress: 'Transcribing audio...', completed: 'Transcribed' },
          { inProgress: 'Summarising session & extracting topics...', completed: 'Summarised' },
        ]}
        subtitle="It could take up to two minutes to upload, transcribe and summarise your session."
        subtitle2="You can close this page and find your session through the sessions page later."
        button={
          <Button component={Link} to="/add-session">
            Import a new session
          </Button>
        }
      />
    )
  }

  return (
    <Container disableGutters sx={{ pl: isMobile ? 0 : 2 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'end',
          pb: 1,
        }}
      >
        <PDFDownloadLink
          document={
            <SummaryPdf
              myName={props.user.name || ''}
              otherPersonName={otherPerson.name}
              summary={summary}
              date={formatDateStringShorter(new Date(session.startTime))}
              duration={session.durationMinutes ? `${session.durationMinutes} mins` : ''}
            />
          }
          fileName={getFileName(otherPerson.name, session.startTime)}
        >
          {({ blob, url, loading, error }) => (
            <LoadingButton
              loading={pdfLoading}
              onClick={() => {
                setPdfLoading(true)
                setTimeout(() => setPdfLoading(false), 3000)
              }}
              sx={{ mx: 1 }}
              disabled={loading}
              startIcon={<SaveAltIcon />}
              variant="contained"
              color="primary"
            >
              Download PDF
            </LoadingButton>
          )}
        </PDFDownloadLink>

        <Button variant="outlined" color="primary" onClick={() => setShowNotes((v) => !v)}>
          {showNotes ? 'Hide' : 'Show'} notes
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'start' : 'end',
          pb: 1,
        }}
      >
        <Typography variant="h4" sx={{ flex: 8, pl: isMobile ? 0 : 1, pb: 1 }}>
          Session with {otherPerson.name}
        </Typography>{' '}
        <Typography variant="subtitle1" sx={{ flex: 1, pr: 1, pb: 1 }}>
          {formatDateStringShorter(new Date(session.startTime))}
        </Typography>
        {session.durationMinutes && (
          <Box sx={{ flex: 1, mr: 1, mb: 1.5 }}>
            <Chip sx={{ height: 25 }} label={`${session.durationMinutes} mins`} color="secondary" />
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '1rem' }}>
        <Box sx={{ gridColumn: !isMobile && showNotes ? '1 / 2' : '1 / 3' }}>
          {summary && <DefaultSummaryV1Section summaryData={summary} />}

          <Paper id="transcript-section" elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ pb: 2 }}>
              Transcript
            </Typography>
            {transcriptParagraphs &&
              transcriptParagraphs.length &&
              transcriptParagraphs.map((para) => (
                <ParagraphBubble key={para.start} paragraph={para} />
              ))}
          </Paper>
        </Box>
        {showNotes && (
          <Paper
            sx={{
              position: 'fixed',
              right: 10,
              top: 139,
              width: isMobile ? '75%' : '24%',
              maxHeight: '75vh',
              overflowY: 'auto',
              p: 3,
            }}
            elevation={3}
          >
            <Typography variant="h5" sx={{ pb: 2 }}>
              Notes
            </Typography>
            <MDXEditor
              autoFocus={{ defaultSelection: 'rootEnd' }}
              ref={editorRef}
              onChange={updateNotes}
              contentEditableClassName="markdown-prose"
              markdown={notes}
              plugins={[
                quotePlugin(),
                headingsPlugin(),
                listsPlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <BlockTypeSelect />
                      <ListsToggle />
                    </>
                  ),
                }),
              ]}
            />
            <Typography variant="caption" sx={{ pt: 2 }}>
              {updateNotesMutation.isPending && 'Saving...'}
              {updateNotesMutation.isSuccess && !isTyping && 'Saved ✅'}
            </Typography>
          </Paper>
        )}
      </Box>
      <TextHighlightButton
        onAddHighlight={(text: string) => {
          const addNotes = () => {
            const currentMarkdown = editorRef.current?.getMarkdown() || ''
            const newMarkdown = `${currentMarkdown}\n\n> ${text}`
            editorRef.current?.setMarkdown(newMarkdown)
            updateNotes(newMarkdown)
            setTimeout(() => {
              editorRef.current?.focus()
            }, 500)
          }
          if (showNotes) {
            addNotes()
          } else {
            setShowNotes(true)
            setTimeout(() => {
              addNotes()
            }, 500)
          }
        }}
      />
      <ComingSoonModal
        open={isComingSoonVisible}
        handleClose={() => setIsComingSoonVisible(false)}
        featureName="Ask AI"
      />
    </Container>
  )
}

export default SessionPage
