import { useState } from 'react'
import {
  Grid,
  TextField,
  Button,
  Typography,
  Box,
  Divider,
  Link,
  useMediaQuery,
  AppBar,
} from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google'

import { auth, googleProvider } from '../firebase'
import UserGraphic from '../assets/authPageGraphic.svg'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import theme from '../theme'
import { usePostUser } from '../api/mutations/postUser'
import posthog from 'posthog-js'
import Logo from '../assets/helioLogo.svg'
import { LoadingButton } from '@mui/lab'
import { ForgotPasswordDialog } from './ForgotPassword'

interface LoginProps {
  toggleScreenType: () => void
  signIn: ({ email, password }: { email: string; password: string }) => void
  signInWithGoogle: () => void
  error: string | null
  loading: boolean
  showForgotPassword: () => void
}

const Login: React.FC<LoginProps> = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const isFormValid = () => email.length > 0 && password.length > 0

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Log in
      </Typography>

      <Typography variant="body1">
        Not registered yet?{' '}
        <Link component="span" onClick={() => props.toggleScreenType()} sx={{ cursor: 'pointer' }}>
          Create an account
        </Link>
      </Typography>

      <Box sx={{ my: 2 }}>
        <Button
          startIcon={<GoogleIcon />}
          variant="outlined"
          fullWidth
          onClick={props.signInWithGoogle}
        >
          Sign in with Google
        </Button>
      </Box>
      <Divider sx={{ my: 2 }}>
        <Typography variant="body1">Or log in with email</Typography>
      </Divider>
      <TextField
        label="Email"
        variant="outlined"
        autoComplete="email"
        fullWidth
        sx={{ mb: 2 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        variant="outlined"
        autoComplete="current-password"
        fullWidth
        type="password"
        sx={{ mb: 1 }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {props.error && (
        <Typography variant="body2" color="error">
          {props.error}
        </Typography>
      )}

      <Typography variant="body1">
        <Link
          component="span"
          onClick={() => props.showForgotPassword()}
          sx={{ cursor: 'pointer' }}
        >
          Forgot password?
        </Link>
      </Typography>
      <Box sx={{ my: 2 }}>
        <LoadingButton
          variant="contained"
          disabled={!isFormValid()}
          loading={props.loading}
          fullWidth
          onClick={() => props.signIn({ email, password })}
        >
          Log in
        </LoadingButton>
      </Box>
    </>
  )
}

type SignUpProps = {
  toggleScreenType: () => void
  signUp: ({ name, email, password }: { name: string; email: string; password: string }) => void
  signInWithGoogle: () => void
  error: string | null
  loading: boolean
}
const SignUp = (props: SignUpProps) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  const isFormValid = () => {
    return name && email && password && password === password2
  }

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Sign up
      </Typography>

      <Typography variant="body1">
        Have an account?{' '}
        <Link component="span" onClick={() => props.toggleScreenType()} sx={{ cursor: 'pointer' }}>
          Log in
        </Link>
      </Typography>

      <Box sx={{ my: 2 }}>
        <Button
          startIcon={<GoogleIcon />}
          variant="outlined"
          fullWidth
          onClick={props.signInWithGoogle}
        >
          Sign in with Google
        </Button>
      </Box>
      <Divider sx={{ my: 2 }}>
        <Typography variant="body1">Or Sign up with email</Typography>
      </Divider>
      <TextField
        required
        label="Name"
        variant="outlined"
        autoComplete="name"
        fullWidth
        sx={{ mb: 2 }}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        required
        label="Email"
        autoComplete="email"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        required
        label="Password"
        variant="outlined"
        autoComplete="new-password"
        fullWidth
        type="password"
        sx={{ mb: 2 }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <TextField
        required
        label="Retype password"
        variant="outlined"
        autoComplete="new-password"
        fullWidth
        type="password"
        sx={{ mb: 1 }}
        value={password2}
        onChange={(e) => setPassword2(e.target.value)}
      />
      {props.error && (
        <Typography variant="body2" color="error">
          {props.error}
        </Typography>
      )}

      <Box sx={{ my: 2 }}>
        <LoadingButton
          variant="contained"
          disabled={!isFormValid()}
          fullWidth
          loading={props.loading}
          onClick={() => props.signUp({ name, email, password })}
        >
          Sign up
        </LoadingButton>
      </Box>
    </>
  )
}
const getErrorMessage = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/email-already-in-use':
      return 'This email address is already in use.'
    case 'auth/invalid-email':
      return 'This email address is not valid.'
    case 'auth/invalid-login-credentials':
      return 'Invalid email or password.'
    case 'auth/user-not-found':
      return 'No user found with this email.'
    case 'auth/wrong-password':
      return 'Incorrect password.'
    case 'auth/weak-password':
      return 'Password is too weak.'
    case 'auth/popup-closed-by-user':
      return 'The popup has been closed by the user before completing the sign in.'
    default:
      return 'An unknown error occurred. Please try again or contact support.'
  }
}

export const Auth = () => {
  const [screenType, setScreenType] = useState<'LOGIN' | 'SIGNUP'>('LOGIN')
  const postUserMutation = usePostUser()
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] = useState(false)

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const toggleScreenType = () => {
    setError(null)
    setScreenType((screenType) => (screenType === 'LOGIN' ? 'SIGNUP' : 'LOGIN'))
  }

  const signUp = async ({
    name,
    email,
    password,
  }: {
    name: string
    email: string
    password: string
  }) => {
    setLoading(true)
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const user = {
        id: userCredential.user.uid,
        email: userCredential.user.email!,
        name: name,
        photoURL: userCredential.user.photoURL,
      }
      posthog.identify(userCredential.user.uid, { email: email, name })
      postUserMutation.mutate(user)
    } catch (err: any) {
      setError(getErrorMessage(err.code))
    } finally {
      setLoading(false)
    }
  }

  const signIn = async ({ email, password }: { email: string; password: string }) => {
    setLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      posthog.identify(userCredential.user.uid)
    } catch (err: any) {
      setError(getErrorMessage(err.code))
    } finally {
      setLoading(false)
    }
  }

  const signInWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider)
      const user = {
        id: userCredential.user.uid,
        email: userCredential.user.email!,
        name: userCredential.user.displayName || undefined,
        photoURL: userCredential.user.photoURL,
      }
      posthog.identify(userCredential.user.uid, {
        email: userCredential.user.email!,
        name: userCredential.user.displayName,
      })
      postUserMutation.mutate(user)
    } catch (err: any) {
      setError(getErrorMessage(err.code))
    }
  }

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundColor: isMobile ? theme.palette.background.default : 'white',
      }}
    >
      {isMobile && (
        <AppBar
          position="fixed"
          sx={{
            borderRadius: 0,
            height: theme.spacing(11),
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              pl: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Logo} alt="Helio Logo" />
            <Typography variant="h4" sx={{ color: 'black', marginLeft: 1 }}>
              Helio
            </Typography>
          </Box>
        </AppBar>
      )}
      {!isMobile && (
        <Grid item sm={4} md={5}>
          <Box
            sx={{
              flexDirection: 'column',
              height: 'auto',
              alignItems: 'flex-start',
              padding: 8,
              color: 'white',
              backgroundColor: theme.palette.primary.main,
              marginLeft: 4,
              my: 4,
              borderRadius: 4,
              minHeight: `calc(100% - ${theme.spacing(24)})`,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <img src={Logo} alt="Helio Logo" />
              <Typography variant="h4">Helio</Typography>
            </Box>
            <Typography variant="h4" gutterBottom sx={{ marginTop: 4 }}>
              Total attention, total recall
            </Typography>
            <Typography variant="h6">
              Capture key insights, action points, and patterns automatically
            </Typography>
            <Box
              sx={{ display: 'flex', marginTop: 4, flexDirection: 'column', alignItems: 'center' }}
            >
              <img style={{ width: '70%' }} src={UserGraphic} alt="Helio Logo" />
            </Box>
          </Box>
        </Grid>
      )}
      <Grid xs={12} sm={12} md={7} container justifyContent="center">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '75%',
            my: 4,
            mt: 15,
            mx: 2,
          }}
        >
          {screenType === 'LOGIN' ? (
            <Login
              toggleScreenType={toggleScreenType}
              signIn={signIn}
              signInWithGoogle={signInWithGoogle}
              error={error}
              loading={loading}
              showForgotPassword={() => setForgotPasswordDialogOpen(true)}
            />
          ) : (
            <SignUp
              toggleScreenType={toggleScreenType}
              signUp={signUp}
              signInWithGoogle={signInWithGoogle}
              error={error}
              loading={loading}
            />
          )}
        </Box>
      </Grid>
      <ForgotPasswordDialog
        forgotPasswordDialogOpen={forgotPasswordDialogOpen}
        setForgotPasswordDialogOpen={setForgotPasswordDialogOpen}
      />
    </Grid>
  )
}
