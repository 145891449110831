import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Popper from '@mui/material/Popper'
import theme from '../theme'

const TextHighlightButton = (props: { onAddHighlight: (text: string) => void }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [selectionText, setSelectionText] = useState<string>('')
  const [open, setOpen] = useState(false)
  const anchorElRef = useRef<HTMLElement | null>(null)
  const offScreenSpanRef = useRef<HTMLSpanElement | null>(null)

  useEffect(() => {
    const offScreenSpan = document.createElement('span')
    offScreenSpan.style.position = 'absolute'
    offScreenSpan.style.left = '0px'
    offScreenSpan.style.top = '0px'
    offScreenSpan.style.width = '0'
    offScreenSpan.style.height = '0'
    document.body.appendChild(offScreenSpan)
    anchorElRef.current = offScreenSpan
    offScreenSpanRef.current = offScreenSpan
  }, [])

  useEffect(() => {
    const handleMouseUp = (event: MouseEvent) => {
      const selection = window.getSelection()
      if (selection && selection.rangeCount > 0 && selection.toString().trim() !== '') {
        const range = selection.getRangeAt(0)
        const startNode = range.startContainer.parentNode as Element

        const summarySection = document.getElementById('summary-section')
        const transcriptSection = document.getElementById('transcript-section')
        if (
          (!summarySection || !summarySection.contains(startNode)) &&
          (!transcriptSection || !transcriptSection.contains(startNode))
        ) {
          // If the selection is outside summary and transcript sections, do nothing
          cleanupAnchor()
          return
        }

        const rects = range.getClientRects()
        if (rects.length > 0) {
          // 1st rect is the start of the selection
          const rect = rects[0]

          const span = document.createElement('span')
          span.style.position = 'absolute'
          span.style.left = `${rect.left + window.scrollX}px`
          span.style.top = `${rect.top + window.scrollY}px`
          span.style.width = '0'
          span.style.height = '0'
          document.body.appendChild(span)

          anchorElRef.current = span
          setAnchorEl(span)
          setSelectionText(selection.toString())
          setOpen(true)
        }
      } else {
        cleanupAnchor()
        setSelectionText('')
      }
    }

    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp)
      cleanupAnchor()
    }
  }, [])

  const cleanupAnchor = () => {
    if (anchorElRef.current && anchorElRef.current !== offScreenSpanRef.current) {
      document.body.removeChild(anchorElRef.current)
      anchorElRef.current = offScreenSpanRef.current
      setAnchorEl(offScreenSpanRef.current)
    }
    setOpen(false)
  }

  const handleClose = () => {
    cleanupAnchor()
  }

  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        disablePortal
        placement="top"
        sx={{ zIndex: 1250 }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-20, 8],
            },
          },
        ]}
      >
        <Button
          variant="contained"
          sx={{
            pointerEvents: 'all',
            backgroundColor: 'white',
            color: theme.palette.primary.main,
            boxShadow:
              '0px 3px 12px rgba(0, 0, 0, 0.1),0px 3px 4px rgba(0, 0, 0, 0.2),0px 1px 10px rgba(0, 0, 0, 0.15)',
            '&:hover': {
              backgroundColor: theme.palette.background.default,
              color: theme.palette.primary.main,
              boxShadow:
                '0px 3px 12px rgba(0, 0, 0, 0.1),0px 3px 4px rgba(0, 0, 0, 0.2),0px 1px 10px rgba(0, 0, 0, 0.15)',
            },
          }}
          onClick={() => {
            props.onAddHighlight(selectionText)
            handleClose()
          }}
        >
          Add to notes
        </Button>
      </Popper>
    </div>
  )
}

export default TextHighlightButton
