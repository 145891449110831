import React, { useState } from 'react'
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Snackbar,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import copyToClipboard from 'copy-to-clipboard'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { TopicChip } from './TopicChip'

export interface DefaultSummaryData {
  summary: string | string[]
  quotes: string[]
  suggestions: string[]
  topics: string[]
  actionPoints: string[]
}

const styles = {
  paper: {
    padding: '20px',
  },

  listItem: {
    paddingLeft: '30px',
  },
  quoteBlock: {
    background: '#2C67E426',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '16px',
  },
  text: {
    lineHeight: '1.6',
  },
}
const formatSummary = (summary: string) => {
  return (
    summary &&
    summary.split('\n').map((paragraph, index) => (
      <Typography key={index} variant="body1" paragraph style={styles.text}>
        {paragraph}
      </Typography>
    ))
  )
}

const Summary = (props: { summary: string | string[] }) => {
  return (
    <Box sx={{ mt: 1 }}>
      {Array.isArray(props.summary) ? (
        props.summary.map((paragraph, index) => (
          <Typography key={index} variant="body1" paragraph style={styles.text}>
            {paragraph}
          </Typography>
        ))
      ) : (
        <Typography variant="body1" paragraph style={styles.text}>
          {formatSummary(props.summary)}
        </Typography>
      )}
    </Box>
  )
}

const SnackbarAction = (props: { handleClose: () => void }) => (
  <IconButton size="small" aria-label="close" color="inherit" onClick={props.handleClose}>
    <CloseIcon fontSize="small" />
  </IconButton>
)

const CopyButton = (props: { onClick: () => void }) => {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation()
        props.onClick()
      }}
      sx={{ ml: 2, color: '#87868B', height: '2.3em' }}
      variant="text"
      startIcon={<ContentCopyIcon htmlColor="#87868B" />}
    >
      Copy section
    </Button>
  )
}
export const DefaultSummaryV1Section: React.FC<{ summaryData: DefaultSummaryData }> = ({
  summaryData,
}) => {
  const [snackbarIsOpen, setSnackbarIsOpen] = useState(false)
  return (
    <Paper id="summary-section" elevation={3} sx={{ mb: 3, p: 2 }}>
      <Accordion elevation={0} sx={{ boxShadow: 'none' }} disableGutters defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5" component="h3">
            Session summary
          </Typography>
          <CopyButton
            onClick={() => {
              const summaryString = Array.isArray(summaryData.summary)
                ? summaryData.summary.join('\n')
                : summaryData.summary
              copyToClipboard(summaryString, { format: 'text/plain' })
              setSnackbarIsOpen(true)
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="h6">Key topics discussed</Typography>
          <Box sx={{ mt: 1 }}>
            {summaryData.topics.map((topic, index) => (
              <TopicChip key={index} topic={topic} maxWidth="auto" mb={1} />
            ))}
          </Box>

          <Divider style={{ margin: '20px 0' }} />
          <Summary summary={summaryData.summary} />
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ boxShadow: 'none' }} defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Highlighted quotes</Typography>
          <CopyButton
            onClick={() => {
              copyToClipboard(`\u2022 ${summaryData.quotes.join('\n\u2022 ')}`, {
                format: 'text/plain',
              })
              setSnackbarIsOpen(true)
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {summaryData.quotes.map((quote, index) => (
              <Paper key={index} style={styles.quoteBlock} elevation={1}>
                <Typography variant="body1" style={styles.text}>{`"${quote}"`}</Typography>
              </Paper>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ boxShadow: 'none' }} defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Action points</Typography>
          <CopyButton
            onClick={() => {
              copyToClipboard(`\u2022 ${summaryData.actionPoints.join('\n\u2022 ')}`, {
                format: 'text/plain',
              })
              setSnackbarIsOpen(true)
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {summaryData.actionPoints.length > 0 ? (
              summaryData.actionPoints.map((actionPoint, index) => (
                <ListItem key={index} style={styles.listItem}>
                  <ListItemIcon>
                    <CheckBoxOutlineBlankIcon />
                  </ListItemIcon>
                  <ListItemText primary={actionPoint} />
                </ListItem>
              ))
            ) : (
              <ListItem style={styles.listItem}>
                <ListItemText primary={'No action points detected'} />
              </ListItem>
            )}
          </List>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ boxShadow: 'none' }} defaultExpanded disableGutters>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">Suggestions for consideration</Typography>
          <CopyButton
            onClick={() => {
              copyToClipboard(`\u2022 ${summaryData.suggestions.join('\n\u2022 ')}`, {
                format: 'text/plain',
              })
              setSnackbarIsOpen(true)
            }}
          />
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {summaryData.suggestions.map((suggestion, index) => (
              <ListItem key={index} style={styles.listItem}>
                <ListItemText primary={suggestion} />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
      <Snackbar
        open={snackbarIsOpen}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => setSnackbarIsOpen(false)}
        message="Copied to clipboard ✅"
        action={<SnackbarAction handleClose={() => setSnackbarIsOpen(false)} />}
      />
    </Paper>
  )
}
