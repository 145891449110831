import React, { useContext, useState } from 'react'
import { Typography, Button, Grid, Box } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'

import { Link } from 'react-router-dom'
import { isProduction } from '../env'
import { useSessions } from '../api'
import SessionCard from '../components/SessionCard'
import ComingSoonModal from '../components/ComingSoonModal'
import GetStartedComponent from '../components/Instructions'
import { AuthContext } from '../auth/AuthProvider'

export const ZOOM_CLIENT_ID = 'gtNowSicSBGAqkbJStlwwg'
export const ZOOM_REDIRECT_URI = isProduction
  ? 'https://app.helio.chat/zoom-redirect'
  : 'http://localhost:3000/zoom-redirect'

export const initiateZoomAuth = async () => {
  try {
    window.location.href = `https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${ZOOM_REDIRECT_URI}`
  } catch (error) {
    console.error('Error during Zoom authentication:', error)
  }
}

const HomePage = () => {
  const [isComingSoonVisible, setIsComingSoonVisible] = useState(false)
  const { user } = useContext(AuthContext)
  const recentSessionsQuery = useSessions()

  return (
    <Box sx={{ p: 3 }}>
      <GetStartedComponent name={(user?.name && user.name.split(' ')[0]) || 'there'} />
      <header>
        <Typography variant="h4" gutterBottom>
          Add a Session
        </Typography>
        <Typography sx={{ mb: 2 }}>
          Choose how to add your session. Either upload audio directly or import from Zoom.
        </Typography>

        <Link to="/add-session">
          <Button variant="contained" startIcon={<UploadFileIcon />} sx={{ mr: 2, mb: 2 }}>
            Upload Audio
          </Button>
        </Link>
        {/* <Link to="/zoom-meetings"> */}
        <Button sx={{ mb: 2 }} variant="outlined" onClick={() => setIsComingSoonVisible(true)}>
          Import from Zoom
        </Button>
        {/* </Link> */}
      </header>

      {recentSessionsQuery.isSuccess && recentSessionsQuery.data.length > 0 && (
        <section>
          <Typography variant="h4" sx={{ mt: 4, mb: 2 }} gutterBottom>
            Recent Sessions
          </Typography>

          <Grid container spacing={2}>
            {recentSessionsQuery.data.map((session, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <SessionCard {...session} />
              </Grid>
            ))}
          </Grid>
        </section>
      )}
      <ComingSoonModal
        open={isComingSoonVisible}
        handleClose={() => setIsComingSoonVisible(false)}
        featureName="Import from Zoom"
      />
    </Box>
  )
}

export default HomePage
