import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../constants'
import { PartialUser, Session } from '../../types'
import { apiGet } from '../utils'

export type SessionWithTopics = Session & { topics: string[] }
interface OtherUserSessionsReturnType {
  otherPerson: PartialUser
  sessions: SessionWithTopics[]
}

export const useSessionsWithOtherPerson = (otherPersonId: string) => {
  return useQuery({
    queryKey: [QueryKey.SESSIONS_WITH_OTHER_USER, otherPersonId],
    queryFn: async () => {
      const result: OtherUserSessionsReturnType = await apiGet(`/people/${otherPersonId}/sessions`)
      return result
    },
    enabled: Boolean(otherPersonId),
  })
}
