import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import { Typography } from '@mui/material'

const MultiStepLoader = (props: {
  steps: { inProgress: string; completed: string }[]
  currentStep: number
  button?: React.ReactNode
  subtitle?: string
  subtitle2?: string
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
    >
      <CircularProgress />
      {props.steps.map((step, i) => {
        const isStepComplete = props.currentStep > i + 1
        return (
          <Typography key={step.inProgress} variant="h6" style={{ marginTop: 20 }}>
            {isStepComplete ? step.completed : step.inProgress} {isStepComplete && '✅'}
          </Typography>
        )
      })}
      {props.subtitle && (
        <Typography variant="subtitle1" style={{ marginTop: 20 }}>
          {props.subtitle}
        </Typography>
      )}
      {props.subtitle2 && (
        <Typography variant="subtitle1" style={{ marginTop: 10 }}>
          {props.subtitle2}
        </Typography>
      )}
      {props.button && <Box style={{ marginTop: 10 }}>{props.button}</Box>}
    </Box>
  )
}

export default MultiStepLoader
