export enum QueryKey {
  BOOTSTRAP = 'bootstrap',
  PEOPLE = 'people',
  SESSIONS = 'sessions',
  SESSION = 'sessionById',
  SESSIONS_WITH_OTHER_USER = 'sessionWithOtherUser',
  UPDATE_OTHER_PERSON = 'updateOtherPerson',
  ZOOM_MEETINGS = 'zoomMeetings',
  STRIPE_CHECKOUT_SESSION = 'stripeCheckoutSession',
  AVAILABLE_SESSIONS = 'availableSessions',
}
