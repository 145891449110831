import React, { useCallback, useRef, useState } from 'react'
import { Paper, Typography, Box } from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

interface FileUploadBoxProps {
  onSelectFile: (file: File) => void
}

const FileUploadBox: React.FC<FileUploadBoxProps> = ({ onSelectFile }) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()
      const files = event.dataTransfer.files
      if (files && files.length > 0) {
        const file = files[0]
        setSelectedFile(file)
        onSelectFile(file)
      }
    },
    [onSelectFile],
  )

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
  }

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0]
      setSelectedFile(file)
      onSelectFile(file)
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Paper
        variant="outlined"
        onDrop={onDrop}
        onDragOver={onDragOver}
        onClick={openFileDialog}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          backgroundColor: '#f5f5f5',
          cursor: 'pointer',
          border: '2px dashed #ccc',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <CloudUploadIcon style={{ fontSize: 40, color: '#757575' }} />
        {selectedFile ? (
          <Typography variant="subtitle1" style={{ color: '#757575', marginTop: '10px' }}>
            {selectedFile.name}
          </Typography>
        ) : (
          <Typography variant="subtitle1" style={{ color: '#757575' }}>
            Click here to upload audio, or drag & drop file
          </Typography>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={onFileChange}
          style={{ display: 'none' }}
          accept="audio/*"
        />
      </Paper>
    </Box>
  )
}

export default FileUploadBox
