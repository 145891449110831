import React, { useEffect } from 'react'
import { Container, Paper, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { usePostCheckoutId } from '../api/mutations/postCheckoutId'

const SubscribeSuccessPage: React.FC = () => {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const sessionId = query.get('session_id')

  const postCheckoutIdMutation = usePostCheckoutId()

  useEffect(() => {
    if (sessionId) {
      postCheckoutIdMutation.mutate(sessionId)
    }
  }, [sessionId, postCheckoutIdMutation])

  return (
    <Container sx={{ ml: 0, p: 3 }} maxWidth="md">
      <Typography variant="h5" sx={{ mb: 3 }}>
        Success
      </Typography>
      <Paper elevation={3} sx={{ p: 3, alignItems: 'center' }}>
        <Typography variant="body1" sx={{ mb: 3 }}>
          You were subscribed successfully
        </Typography>
      </Paper>
    </Container>
  )
}

export default SubscribeSuccessPage
