import React from 'react'
import { createRoot } from 'react-dom/client'
import posthog from 'posthog-js'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import * as Sentry from '@sentry/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

import App from './App'
import { AuthProvider } from './auth/AuthProvider'
import reportWebVitals from './reportWebVitals'
import { FUNCTIONS_BASE_URL, isProduction } from './env'
import { escapeRegExp } from './utils'

if (isProduction) {
  Sentry.init({
    dsn: 'https://152adff72e225338fb2516ed4c2d67f8@o4506737141415936.ingest.sentry.io/4506737172676608',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', new RegExp(`^${escapeRegExp(FUNCTIONS_BASE_URL)}`)],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
  posthog.init('phc_tvQvgh9lo8FwohiCN0tIy19veIqNqp37WOtD9fuLd0e', {
    api_host: 'https://eu.i.posthog.com',
  })
}

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </LocalizationProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>,
)

reportWebVitals()
