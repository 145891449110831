import React, { useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Box, useMediaQuery } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { Auth } from './auth/Auth'
import { AuthContext } from './auth/AuthProvider'
import { Sidebar, MobileAppBar, SIDEBAR_WIDTH } from './components/Menus'
import theme from './theme'
// Pages
import HomePage from './pages/HomePage'
import AddSessionPage from './pages/AddSessionPage'
import SessionPage from './pages/SessionPage'
import ZoomCallbackHandler from './pages/ZoomCallbackHandler'
import ZoomMeetingsList from './pages/ZoomMeetingsList'
import SessionsPage from './pages/SessionsPage'
import PeoplePage from './pages/PeoplePage'
import PersonPage from './pages/PersonPage'
import ContactUsPage from './pages/ContactUsPage'
import SubscribePage from './pages/SubscribePage'
import SubscribeSuccessPage from './pages/SubscribeSuccessPage'

const App: React.FC = () => {
  const { user } = useContext(AuthContext)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        {!user ? (
          <Auth />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              minHeight: '100vh',
              background: theme.palette.background.default,
            }}
          >
            {isMobile ? <MobileAppBar /> : <Sidebar />}
            <Box
              component="main"
              sx={{
                p: 3,
                mt: isMobile ? 8 : 0,
                width: isMobile ? 'auto' : { sm: `calc(100% - ${SIDEBAR_WIDTH}px)` },
              }}
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/add-session" element={<AddSessionPage user={user} />} />
                <Route path="/sessions" element={<SessionsPage />} />
                <Route path="/people" element={<PeoplePage user={user} />} />
                <Route path="/zoom-redirect" element={<ZoomCallbackHandler />} />
                <Route path="/zoom-meetings" element={<ZoomMeetingsList />} />
                <Route path="/session/:sessionId" element={<SessionPage user={user} />} />
                <Route path="/person/:otherPersonId" element={<PersonPage />} />
                <Route path="/contact-us" element={<ContactUsPage />} />
                <Route path="/subscribe" element={<SubscribePage />} />
                <Route path="/success" element={<SubscribeSuccessPage />} />
              </Routes>
            </Box>
          </Box>
        )}
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
