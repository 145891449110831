import React from 'react'
import { Link } from 'react-router-dom'
import LoadingButton from '@mui/lab/LoadingButton'
import { Typography, Button, List, Card, CardContent, Container } from '@mui/material'

import FullScreenLoader from '../components/FullScreenLoader'
import { formatDateString } from '../utils'
import { initiateZoomAuth } from './HomePage'
import { useZoomMeetings } from '../api'
import { useImportZoomMeeting } from '../api'

const MeetingListItem = (props: { meeting: any }) => {
  const importZoomMeetingMutation = useImportZoomMeeting()
  return (
    <Card variant="elevation" style={{ marginBottom: '10px' }}>
      {' '}
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {' '}
          {props.meeting.topic}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          {formatDateString(props.meeting.start_time)} - {props.meeting.duration} mins
        </Typography>
        <div style={{ marginTop: '10px' }}>
          {props.meeting.imported && props.meeting.helioId ? (
            <Link to={`/session/${props.meeting.helioId}`}>
              <Button variant="contained" color="primary" style={{ marginRight: '10px' }}>
                View meeting
              </Button>
            </Link>
          ) : (
            <LoadingButton
              variant="contained"
              color="secondary"
              loading={importZoomMeetingMutation.isPending}
              onClick={() => importZoomMeetingMutation.mutate(props.meeting)}
            >
              Import meeting
            </LoadingButton>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

export const MeetingsList = () => {
  const zoomMeetingsQuery = useZoomMeetings()

  if (zoomMeetingsQuery.isLoading) {
    return <FullScreenLoader />
  }

  if (zoomMeetingsQuery.isError) {
    return (
      <div style={{ padding: '20px' }}>
        <Typography variant="body1" color="error">
          Error: {zoomMeetingsQuery.error.message}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => initiateZoomAuth()}>
          Re-authenticate with Zoom
        </Button>
      </div>
    )
  }

  return (
    <Container style={{ alignSelf: 'start', marginTop: '25px' }}>
      <Typography variant="h4">Meetings</Typography>
      {zoomMeetingsQuery.isSuccess && zoomMeetingsQuery.data.length > 0 ? (
        <List>
          {zoomMeetingsQuery.data.map((meeting: any, index: number) => (
            <MeetingListItem meeting={meeting} key={index} />
          ))}
        </List>
      ) : (
        <Typography variant="body1">No meetings found.</Typography>
      )}
    </Container>
  )
}

export default MeetingsList
