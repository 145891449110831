import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../constants'
import { User } from '../../types'
import { apiGet } from '../utils'

export const useBootstrap = (userId?: string) => {
  return useQuery({
    queryKey: [QueryKey.BOOTSTRAP],
    queryFn: async () => {
      const result = await apiGet<User>('/bootstrap')
      return result
    },
    enabled: Boolean(userId),
  })
}
