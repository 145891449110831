import { Chip } from '@mui/material'
import theme from '../theme'

export const TopicChip = (props: { topic: string; maxWidth: string; mb?: number }) => (
  <Chip
    sx={{
      mr: 1,
      mb: props.mb || 0,
      fontSize: theme.typography.caption,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: 25,
      maxWidth: props.maxWidth,
    }}
    label={props.topic.toLowerCase()}
    color="secondary"
  />
)
