import axios from 'axios'
import { getAuth } from 'firebase/auth'
import { API_BASE_URL } from '../env'

export const apiGet = async <T>(url: string): Promise<T> => {
  const auth = getAuth()
  const user = auth.currentUser

  if (!user) {
    throw new Error('User not authenticated')
  }

  const idToken = await user.getIdToken()

  const result = await axios.get<T>(`${API_BASE_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })

  return result.data
}

export const apiPut = async <B, T>(url: string, body: B): Promise<T> => {
  const auth = getAuth()
  const user = auth.currentUser

  if (!user) {
    throw new Error('User not authenticated')
  }

  const idToken = await user.getIdToken()

  const result = await axios.put<T>(`${API_BASE_URL}${url}`, body, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })

  return result.data
}

export const apiPost = async <B, T>(url: string, body: B): Promise<T> => {
  const auth = getAuth()
  const user = auth.currentUser

  if (!user) {
    throw new Error('User not authenticated')
  }

  const idToken = await user.getIdToken()

  const result = await axios.post<T>(`${API_BASE_URL}${url}`, body, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  })

  return result.data
}
