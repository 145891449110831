import * as React from 'react'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { PartialUser } from '../types'

export interface UserPickerPartialUser extends PartialUser {
  // inputValue is used for the freeSolo option when adding a new option
  inputValue?: string
}

interface UserPickerProps {
  users: UserPickerPartialUser[]
  pickUser: (user: UserPickerPartialUser | null) => void
}
const filter = createFilterOptions<UserPickerPartialUser>()
export default function UserPicker(props: UserPickerProps) {
  const [value, setValue] = React.useState<UserPickerPartialUser | null>(null)
  const [open, toggleOpen] = React.useState(false)

  const [dialogValue, setDialogValue] = React.useState({
    name: '',
    email: '',
  })

  const handleClose = () => {
    setDialogValue({
      name: '',
      email: '',
    })
    toggleOpen(false)
  }

  const pickUser = (user: UserPickerPartialUser | null) => {
    props.pickUser(user)
    setValue(user)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    pickUser({
      name: dialogValue.name,
      email: dialogValue.email,
    })
    handleClose()
  }

  return (
    <React.Fragment>
      <Autocomplete
        placeholder="Search for a user"
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            // timeout to avoid instant validation of the dialog's form.
            setTimeout(() => {
              toggleOpen(true)
              setDialogValue({
                name: newValue,
                email: '',
              })
            })
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true)
            setDialogValue({
              name: newValue.inputValue,
              email: '',
            })
          } else {
            pickUser(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            })
          }

          return filtered
        }}
        id="user-picker"
        options={props.users}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.inputValue) {
            return option.inputValue
          }
          return option.name
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => <TextField {...params} />}
      />
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Add a new person</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mr: 2 }}
              autoFocus
              margin="normal"
              id="name"
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: event.target.value,
                })
              }
              label="name"
              type="input"
              variant="standard"
            />
            <TextField
              margin="normal"
              id="email"
              value={dialogValue.email}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  email: event.target.value,
                })
              }
              label="email (optional)"
              type="input"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  )
}
