import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat,sans-serif',
  },
  palette: {
    primary: {
      main: '#2C67E4',
      light: '#169bff',
      dark: '#2e44c4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#58BFA8',
      light: '#87d2c1',
      dark: '#219479',
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#F6F6F6',
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: '#EEF8F6',
          color: '#2C67E4',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: { pointerEvents: 'none' },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '16px',
          border: 'none',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
          color: 'inherit',
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '16px',
          height: '48px',
        },
      },
    },
  },
})

export default theme
