import * as React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Fade from '@mui/material/Fade'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />
})

const ComingSoonModal = (props: {
  open: boolean
  handleClose: () => void
  featureName: string
}) => {
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="coming-soon-dialog-description"
    >
      <DialogTitle>{'Coming soon'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="coming-soon-dialog-description">
          The "{props.featureName}" feature is coming soon - check back soon. If you have feedback
          or suggestions, we would love to hear!
        </DialogContentText>
        <br />
        <DialogContentText id="coming-soon-dialog-description">
          Let us know via the instant chat or the <Link to="/contact-us">Contact Us page</Link>. We
          read every suggestion. <span style={{ color: 'black' }}>😊</span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Got it</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ComingSoonModal
