// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCzUmxKmy4SEKo5-qmpn3XsANNWwhmHWY8',
  authDomain: 'app.helio.chat',
  projectId: 'coachee-ef3ee',
  storageBucket: 'coachee-ef3ee.appspot.com',
  messagingSenderId: '242646469279',
  appId: '1:242646469279:web:d85f2cd7283913bc15f0aa',
  measurementId: 'G-BEV7WDX2WB',
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const functions = getFunctions(app)
export const storage = getStorage(app)

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

export const googleProvider = new GoogleAuthProvider()
export default app
