import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  ListItemButton,
  LinearProgress,
  Button,
  useTheme,
  useMediaQuery,
  AppBar,
  IconButton,
} from '@mui/material'
import { Link, useLocation, matchPath, useNavigate } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

import HomeIcon from '../assets/icons/home.svg'
import PeopleIcon from '../assets/icons/people.svg'
import SessionsIcon from '../assets/icons/audio.svg'
import ImportIcon from '../assets/icons/import.svg'
import ContactUsIcon from '../assets/icons/chat.svg'
import HomeIconWhite from '../assets/icons/white/home.svg'
import PeopleIconWhite from '../assets/icons/white/people.svg'
import SessionsIconWhite from '../assets/icons/white/audio.svg'
import ImportIconWhite from '../assets/icons/white/import.svg'
import ContactUsIconWhite from '../assets/icons/white/chat.svg'

import Logo from '../assets/helioLogo.svg'
import theme from '../theme'
import { auth } from '../firebase'
import posthog from 'posthog-js'
import { useEntitlements } from '../api/queries/getEntitlements'

export const SIDEBAR_WIDTH = 280

const SIDEBAR_LINKS = [
  { paths: ['/'], label: 'Home', icon: HomeIcon, iconWhite: HomeIconWhite },
  {
    paths: ['/people', '/person/:otherPersonId'],
    label: 'People',
    icon: PeopleIcon,
    iconWhite: PeopleIconWhite,
  },
  {
    paths: ['/sessions', '/session/:sessionId'],
    label: 'Sessions',
    icon: SessionsIcon,
    iconWhite: SessionsIconWhite,
  },
  { paths: ['/add-session'], label: 'Add session', icon: ImportIcon, iconWhite: ImportIconWhite },
  {
    paths: ['/contact-us'],
    label: 'Contact us',
    icon: ContactUsIcon,
    iconWhite: ContactUsIconWhite,
  },
]

const SidebarLink = ({
  label,
  icon,
  iconWhite,
  paths,
}: {
  label: string
  icon: string
  iconWhite: string
  paths: string[]
}) => {
  const location = useLocation()
  const isCurrentRoute = paths.some((path) => {
    const match = matchPath(path, location.pathname)
    return match !== null
  })
  return (
    <ListItem
      key={label}
      disablePadding
      sx={{
        margin: 2,
        borderRadius: 4,
        backgroundColor: isCurrentRoute
          ? theme.palette.secondary.main
          : theme.palette.background.default,
        color: isCurrentRoute ? 'white' : 'black',
        '&:hover': {
          backgroundColor: isCurrentRoute ? theme.palette.secondary.main : '#E7E7E7',
        },
      }}
    >
      <ListItemButton
        component={Link}
        to={paths[0]}
        disableRipple
        sx={{
          width: '100%',
          borderRadius: 4,
        }}
      >
        <ListItemIcon>
          <img src={isCurrentRoute ? iconWhite : icon} alt={label} />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  )
}

const LogoutLink = (props: { logOut: () => void }) => (
  <ListItem
    key={'Log out'}
    onClick={props.logOut}
    disablePadding
    sx={{
      margin: 2,
      borderRadius: 4,
      backgroundColor: theme.palette.background.default,
      color: 'black',
      '&:hover': {
        backgroundColor: '#E7E7E7',
      },
    }}
  >
    <ListItemButton
      disableRipple
      sx={{
        width: '100%',
        borderRadius: 4,
      }}
    >
      <ListItemIcon>
        <img src={HomeIcon} alt={'Log out'} />
      </ListItemIcon>
      <ListItemText primary={'Log out'} />
    </ListItemButton>
  </ListItem>
)

const formatPlan = (plan: string) => {
  return plan[0].toUpperCase() + plan.slice(1).toLowerCase()
}

export const MobileAppBar = () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          borderRadius: 0,
          height: theme.spacing(10),
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          backgroundColor: 'white',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              marginTop: 2,
              pl: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Logo} alt="Helio Logo" />
            <Typography variant="h4" sx={{ color: 'black', marginLeft: 1 }}>
              Helio
            </Typography>
          </Box>
        </Link>
        <IconButton
          onClick={() => setDrawerOpen(true)}
          edge="end"
          color="primary"
          aria-label="open drawer"
          sx={{ mr: 2 }}
        >
          <MenuIcon sx={{ height: '1.5em', width: '1.5em' }} />
        </IconButton>
      </AppBar>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        sx={{
          borderRadius: 0,
          '& .MuiDrawer-paper': {
            width: '70%',
            borderRadius: 0,
            height: '100%',
            boxSizing: 'border-box',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
            border: 'none',
            backgroundColor: '#fff',
          },
        }}
      >
        <Box
          sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
        >
          <IconButton
            onClick={() => setDrawerOpen(false)}
            edge="end"
            aria-label="open drawer"
            sx={{ mr: 1 }}
          >
            <CloseIcon sx={{ height: '1.5em', width: '1.5em' }} />
          </IconButton>
        </Box>
        <List sx={{ padding: 0, backgroundColor: 'white', width: 240, mt: -2 }}>
          {SIDEBAR_LINKS.map(({ paths, label, icon, iconWhite }) => (
            <SidebarLink
              key={label}
              paths={paths}
              label={label}
              icon={icon}
              iconWhite={iconWhite}
            />
          ))}
          <LogoutLink logOut={() => {}} />
        </List>
      </Drawer>
    </>
  )
}
export const Sidebar = () => {
  const navigate = useNavigate()
  const logOut = async () => {
    try {
      await signOut(auth)
      posthog.reset()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const entitlementsQuery = useEntitlements()
  if (isMobile) {
    return (
      <AppBar
        position="fixed"
        sx={{
          height: '10%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          backgroundColor: 'white',
        }}
      >
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              marginTop: 2,
              pl: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={Logo} alt="Helio Logo" />
            <Typography variant="h4" sx={{ color: 'black', marginLeft: 1 }}>
              Helio
            </Typography>
          </Box>
        </Link>
        <IconButton size="large" edge="end" color="primary" aria-label="open drawer" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
      </AppBar>
    )
  }
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: SIDEBAR_WIDTH,
        '& .MuiDrawer-paper': {
          width: SIDEBAR_WIDTH,
          height: '90vh',
          boxSizing: 'border-box',
          borderRadius: '28px',
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
          border: 'none',
          backgroundColor: '#fff',
          marginTop: 3,
          marginBottom: 3,
          marginLeft: 3,
        },
      }}
    >
      <Link to="/" style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            backgroundColor: 'white',
            height: '64px',
            display: 'flex',
            marginTop: 2,
            marginLeft: -3,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={Logo} alt="Helio Logo" />
          <Typography variant="h4" sx={{ color: 'black', marginLeft: 1 }}>
            Helio
          </Typography>
        </Box>
      </Link>

      <List sx={{ padding: 0, backgroundColor: 'white', width: 240 }}>
        {SIDEBAR_LINKS.map(({ paths, label, icon, iconWhite }) => (
          <SidebarLink key={label} paths={paths} label={label} icon={icon} iconWhite={iconWhite} />
        ))}
        <LogoutLink logOut={logOut} />
      </List>
      {entitlementsQuery.data && (
        <Box sx={{ mx: 3 }}>
          <Typography
            variant="body2"
            sx={{
              width: '80%',
              marginTop: 2,
              textWrap: 'wrap',
              fontSize: theme.typography.caption,
            }}
          >
            {entitlementsQuery.data?.availableSessions} session
            {entitlementsQuery.data?.availableSessions === 1 ? '' : 's'} remaining
          </Typography>
          <LinearProgress
            sx={{ mb: 1, mt: 1 }}
            variant="determinate"
            value={
              (entitlementsQuery.data?.availableSessions /
                entitlementsQuery.data?.totalSessionsInPeriod) *
              100
            }
          />
          {entitlementsQuery.data?.activeEntitlement.productId === 'TRIAL' && (
            <Link to="subscribe">
              <Button sx={{ width: '100%', mt: 1 }} variant="outlined">
                Subscribe
              </Button>
            </Link>
          )}
          {entitlementsQuery.data?.activeEntitlement.productId !== 'TRIAL' &&
            entitlementsQuery.data?.activeEntitlement.periodEnd && (
              <>
                <Typography
                  gutterBottom
                  variant="caption"
                  sx={{
                    width: '80%',
                    marginTop: 2,
                    textWrap: 'wrap',
                    fontSize: theme.typography.caption,
                  }}
                >
                  Your plan:{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {formatPlan(entitlementsQuery.data?.activeEntitlement.productId)}
                  </span>
                </Typography>
                <br />
                <Typography
                  variant="caption"
                  sx={{
                    width: '80%',
                    marginTop: 2,
                    textWrap: 'wrap',
                    fontSize: theme.typography.caption,
                  }}
                >
                  Renews on:{' '}
                  {new Date(
                    entitlementsQuery.data?.activeEntitlement.periodEnd,
                  ).toLocaleDateString()}
                </Typography>
              </>
            )}
        </Box>
      )}
    </Drawer>
  )
}
