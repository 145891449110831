import React, { useState, useCallback, useMemo, useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { DateTimePicker } from '@mui/x-date-pickers'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import StopIcon from '@mui/icons-material/Stop'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import HelpIcon from '@mui/icons-material/Help'

import UserPicker from '../components/UserPicker'
import FileUploadBox from '../components/FileUploadBox'
import MultiStepLoader from '../components/MultiStepLoader'
import MicrophoneSvg from '../assets/icons/microphone.svg'
import MicrophoneWhiteSvg from '../assets/icons/microphoneWhite.svg'
import UploadSvg from '../assets/icons/uploadIcon.svg'

import { useAddSession } from '../api'
import { PartialUser, User } from '../types'
import { useUploadFile } from '../utils'
import { useEntitlements } from '../api/queries/getEntitlements'

interface AddSessionPageProps {
  user: User
}

enum AddSessionType {
  RECORD = 'RECORD',
  UPLOAD = 'UPLOAD',
  ZOOM = 'ZOOM',
}

const UploadFileContent = (props: {
  setDateTime: (dateTime: Date | null) => void
  setOtherUser: (user: PartialUser | null) => void
  dateTime: Date | null
  user: User
  isSubmitDisabled: boolean
  handleSubmit: (file: File | Blob) => void
  setAddSessionType: (type: AddSessionType | null) => void
}) => {
  const [file, setFile] = useState<File | Blob | null>(null)
  return (
    <>
      <Button
        onClick={() => props.setAddSessionType(null)}
        startIcon={<ArrowBackIcon />}
        variant="text"
        sx={{ mb: 1, height: '2.5em' }}
      >
        Back
      </Button>
      <FileUploadBox onSelectFile={(file) => setFile(file)} />

      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel id="session-datetime-label">Session date & time:</FormLabel>
        <DateTimePicker
          value={props.dateTime}
          onChange={(newValue) => props.setDateTime(newValue)}
          ampm={false}
          disableFuture
          closeOnSelect={false}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel id="session-user-label">Session with:</FormLabel>
        <UserPicker pickUser={(user) => props.setOtherUser(user)} users={props.user.people} />
      </FormControl>

      <Button
        variant="contained"
        disabled={!file || props.isSubmitDisabled}
        onClick={() => file && props.handleSubmit(file)}
        sx={{ mt: 2 }}
      >
        Submit session
      </Button>
    </>
  )
}

const RecordAudioContent = (props: {
  setDateTime: (dateTime: Date | null) => void
  setOtherUser: (user: PartialUser | null) => void
  dateTime: Date | null
  user: User
  isSubmitDisabled: boolean
  handleSubmit: (file: File | Blob) => void
  setAddSessionType: (type: AddSessionType | null) => void
}) => {
  const [maxRecordingTime, setMaxRecordingTime] = useState<number>(70)
  const {
    startRecording,
    stopRecording,
    togglePauseResume,
    recordingBlob,
    isRecording,
    isPaused,
    recordingTime,
    mediaRecorder,
  } = useAudioRecorder()
  useEffect(() => {
    if (recordingBlob) {
      props.handleSubmit(recordingBlob)
    }
  }, [recordingBlob, props])

  const minutesPassed = recordingTime / 60

  const handleStopRecording = useCallback(() => {
    mediaRecorder?.stream?.getTracks().forEach((track) => track.stop())
    stopRecording()
  }, [mediaRecorder, stopRecording])

  useEffect(() => {
    if (maxRecordingTime >= 10 && minutesPassed && minutesPassed >= maxRecordingTime) {
      handleStopRecording()
    }
  }, [minutesPassed, handleStopRecording, maxRecordingTime])

  const maxRecordingInputError = maxRecordingTime < 10 || maxRecordingTime > 120
  if (isRecording || isPaused) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button
          onClick={() => props.setAddSessionType(null)}
          startIcon={<ArrowBackIcon />}
          variant="text"
          sx={{ mb: 1, height: '2.5em' }}
        >
          Back
        </Button>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <div
            className="dot"
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: isPaused ? 'transparent' : 'red',
              display: 'inline-block',
              marginRight: '5px',
            }}
          />
          <Typography variant="h6">
            {' '}
            {isPaused ? 'Paused' : 'Recording'}: {Math.floor(recordingTime / 3600)}:
            {Math.floor((recordingTime % 3600) / 60)
              .toString()
              .padStart(2, '0')}
            :{(recordingTime % 60).toString().padStart(2, '0')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography variant="body1">Automatically stop recording after</Typography>
          <TextField
            sx={{ ml: 1, width: '4em' }}
            inputProps={{ sx: { height: '1em', py: '5px', px: '10px' } }}
            error={maxRecordingInputError}
            type="number"
            value={String(maxRecordingTime)}
            onChange={(event) => setMaxRecordingTime(Number(event.target.value))}
          />
          <Typography sx={{ mx: 1 }} variant="body1">
            mins.
          </Typography>
          <Tooltip title="The session will automatically stop recording after this many minutes, if you do not manually end it.">
            <HelpIcon sx={{ width: '.7em' }} />
          </Tooltip>
        </Box>
        {maxRecordingInputError && (
          <Typography variant="caption" sx={{ color: 'rgb(232, 59, 70)' }}>
            Session must be between 10 and 120 minutes.
          </Typography>
        )}
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Button
            onClick={togglePauseResume}
            sx={{ width: '13.5em' }}
            startIcon={isPaused ? <PlayArrowIcon /> : <PauseIcon />}
            variant="outlined"
            disabled={!isRecording}
          >
            {isPaused ? 'Resume' : 'Pause'} recording
          </Button>
          <Button
            onClick={handleStopRecording}
            sx={{ width: '13.5em', ml: 1 }}
            startIcon={<StopIcon />}
            variant="outlined"
            disabled={!isRecording}
          >
            Stop recording
          </Button>
        </Box>
      </Box>
    )
  }
  return (
    <>
      <Button
        onClick={() => props.setAddSessionType(null)}
        startIcon={<ArrowBackIcon />}
        variant="text"
        sx={{ mb: 1, height: '2.5em' }}
      >
        Back
      </Button>
      <Typography variant="body1">
        Select who the session is with, and click "Start recording" when ready.{' '}
        <strong>If you are recording a video call, ensure you are not using headphones.</strong>{' '}
      </Typography>

      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel id="session-user-label">Session with:</FormLabel>
        <UserPicker pickUser={(user) => props.setOtherUser(user)} users={props.user.people} />
      </FormControl>

      <Button
        variant="contained"
        disabled={props.isSubmitDisabled}
        startIcon={<img src={MicrophoneWhiteSvg} alt="microphone" />}
        onClick={startRecording}
        sx={{ mt: 2 }}
      >
        Start recording
      </Button>
    </>
  )
}

const ChooseAddSessionType = (props: {
  zeroAvailableSessions: boolean
  setAddSessionType: (type: AddSessionType) => void
}) => {
  return (
    <Box>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Choose how you'd like to add a session
      </Typography>
      <Button
        startIcon={<img src={UploadSvg} alt="upload" />}
        variant="outlined"
        disabled={props.zeroAvailableSessions}
        onClick={() => props.setAddSessionType(AddSessionType.UPLOAD)}
        sx={{ m: 1, width: '50%' }}
      >
        Upload a file
      </Button>
      <Button
        startIcon={<img src={MicrophoneSvg} alt="microphone" />}
        variant="outlined"
        disabled={props.zeroAvailableSessions}
        sx={{ m: 1, width: '50%' }}
        onClick={() => props.setAddSessionType(AddSessionType.RECORD)}
      >
        Record audio
      </Button>
    </Box>
  )
}

const AddSessionPage: React.FC<AddSessionPageProps> = ({ user }) => {
  const navigate = useNavigate()
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [otherPerson, setOtherUser] = useState<PartialUser | null>(null)
  const [dateTime, setDateTime] = useState<Date | null>(new Date())
  const addSessionMutation = useAddSession()
  const { uploadFile } = useUploadFile()
  const entitlementsQuery = useEntitlements()
  const [addSessionType, setAddSessionType] = useState<AddSessionType | null>(null)

  const handleSubmit = useCallback(
    async (file: File | Blob) => {
      if (!file || !user || !otherPerson) {
        return
      }
      setIsFileUploading(true)
      try {
        const filePath = await uploadFile(
          file,
          user.id,
          'name' in file ? file.name : 'session.webm',
        )
        addSessionMutation.mutate(
          {
            filePath,
            otherPerson: otherPerson!,
            dateTime,
          },
          {
            onSuccess: (data) => {
              navigate(`/session/${data.sessionId}`)
            },
            onError: (error) => {
              console.error('Failed to create session', error)
            },
          },
        )
      } catch (uploadError) {
        console.error('File upload failed', uploadError)
      }
    },
    [user, otherPerson, dateTime, uploadFile, addSessionMutation, navigate],
  )

  const zeroAvailableSessions = Boolean(
    entitlementsQuery.data && entitlementsQuery.data.availableSessions === 0,
  )
  const isSubmitDisabled = useMemo(
    () => Boolean(!user || !otherPerson || zeroAvailableSessions),
    [user, otherPerson, zeroAvailableSessions],
  )

  if (addSessionMutation.isPending || isFileUploading) {
    return (
      <MultiStepLoader
        currentStep={1}
        steps={[{ inProgress: 'Uploading file...', completed: 'Uploaded' }]}
        subtitle="It could take up to two minutes to upload, transcribe and summarise your session."
      />
    )
  }

  return (
    <Container sx={{ ml: 0, p: 3 }} maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Add Session
      </Typography>
      <Paper elevation={3} sx={{ p: 4, alignItems: 'center' }}>
        {addSessionType === null && (
          <ChooseAddSessionType
            zeroAvailableSessions={zeroAvailableSessions}
            setAddSessionType={setAddSessionType}
          />
        )}
        {addSessionType === AddSessionType.UPLOAD && (
          <UploadFileContent
            setDateTime={setDateTime}
            setOtherUser={setOtherUser}
            dateTime={dateTime}
            user={user}
            isSubmitDisabled={isSubmitDisabled}
            handleSubmit={handleSubmit}
            setAddSessionType={setAddSessionType}
          />
        )}
        {addSessionType === AddSessionType.RECORD && (
          <RecordAudioContent
            setDateTime={setDateTime}
            setOtherUser={setOtherUser}
            dateTime={dateTime}
            user={user}
            isSubmitDisabled={isSubmitDisabled}
            handleSubmit={handleSubmit}
            setAddSessionType={setAddSessionType}
          />
        )}

        {zeroAvailableSessions && (
          <Box>
            <Typography variant="caption" sx={{ color: 'rgb(232, 59, 70)', mt: 2 }}>
              You have no available sessions. Please subscribe to continue.
            </Typography>
          </Box>
        )}
      </Paper>
    </Container>
  )
}

export default AddSessionPage
