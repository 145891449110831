import React from 'react'
import PricingTable from '../components/PricingTable'
import { Container, Typography } from '@mui/material'
import theme from '../theme'

const SubscribePage = () => {
  return (
    <Container sx={{ ml: 0, p: 3 }} maxWidth="lg">
      <Typography variant="h4" sx={{ flexGrow: 1, px: theme.spacing(2) }}>
        Subscribe
      </Typography>
      <PricingTable />
    </Container>
  )
}

export default SubscribePage
