import React from 'react'
import { Container, Typography, Paper } from '@mui/material'
import theme from '../theme'

const ContactUsPage: React.FC = () => {
  return (
    <Container sx={{ ml: 0, py: 3, px: 0 }} maxWidth="md">
      <Typography variant="h5" sx={{ mb: 3 }}>
        Contact us
      </Typography>
      <Paper elevation={3} sx={{ p: 3, alignItems: 'center' }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Welcome to Helio!
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Do you have questions, need technical support, or want to share feedback? We'd love to
          hear! Get in touch via:
        </Typography>
        <Typography variant="body1" sx={{ mb: 1 }}>
          Email:{' '}
          <a href="mailto:info@helio.chat" style={{ color: theme.palette.primary.main }}>
            info@helio.chat
          </a>
        </Typography>
        <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
          Whatsapp:{' '}
          <a href="https://wa.me/+380635798830" style={{ color: theme.palette.primary.main }}>
            +380 63 579 8830
          </a>
        </Typography>
      </Paper>
    </Container>
  )
}

export default ContactUsPage
