import React from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { useSessions } from '../api'
import SessionCard from '../components/SessionCard'
import FullScreenLoader from '../components/FullScreenLoader'

const SessionsPage: React.FC = () => {
  const recentSessionsQuery = useSessions()
  if (recentSessionsQuery.isLoading) {
    return <FullScreenLoader />
  }
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Recent Sessions
      </Typography>
      <Grid container spacing={2}>
        {recentSessionsQuery.isSuccess &&
          recentSessionsQuery.data.map((session, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SessionCard {...session} />
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
export default SessionsPage
