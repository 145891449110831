import { useMutation, useQueryClient } from '@tanstack/react-query'

import { QueryKey } from '../constants'
import { PartialUser } from '../../types'
import { apiPut } from '../utils'

export const useUpdateOtherPerson = ({ onSuccess }: { onSuccess: () => void }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (otherPerson: PartialUser) => {
      const result = await apiPut<{ otherPerson: PartialUser }, void>('/people', { otherPerson })
      return result
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.SESSIONS_WITH_OTHER_USER] })
      onSuccess()
    },
  })
}
