import { useMutation } from '@tanstack/react-query'

import { apiPost } from '../utils'

export const usePostCheckoutSession = () =>
  useMutation<{ url: string }, Error, { priceId: string }>({
    mutationFn: async ({ priceId }: { priceId: string }) => {
      const result = await apiPost<{ priceId: string }, { url: string }>('/checkout-session', {
        priceId,
      })
      return result
    },
  })
