import React from 'react'
import { Typography, Button, Card, CardContent, Box } from '@mui/material'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'

import { Session } from '../types'
import { SessionWithOtherUser } from '../api/queries/getSessions'

const SessionCard: React.FC<Session> = (props: SessionWithOtherUser) => {
  return (
    <Card variant="outlined" sx={{ mb: 2 }}>
      <CardContent>
        <Typography sx={{ mx: 1, mb: 1 }} fontWeight="bold" variant="h4">
          {props.otherPerson?.name}
        </Typography>

        <Box
          sx={{
            mx: 1,
            mb: 1,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography color="text.secondary">Date:</Typography>
          <Typography>{format(new Date(props.startTime), 'dd MMM yyyy, HH:mm')}</Typography>
        </Box>

        <Box
          sx={{
            mx: 1,
            mb: 2,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography color="text.secondary">Duration:</Typography>
          <Typography>{`${props.durationMinutes || 0} mins`}</Typography>
        </Box>

        <Link style={{ width: '90%' }} to={`/session/${props.id}`}>
          <Button style={{ width: '100%' }} variant="contained">
            View session
          </Button>
        </Link>
      </CardContent>
    </Card>
  )
}

export default SessionCard
