import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '../constants'
import { DefaultSummaryData } from '../../components/DefaultSummaryV1Section'
import { Paragraph, PartialUser, Session } from '../../types'
import { apiGet } from '../utils'

export interface AudioRecord {
  id: string
  userId: string
  deepgramResult?: object
  storagePath: string
}

export const useSession = (id: string | undefined) => {
  return useQuery({
    queryKey: [QueryKey.SESSION, id],
    queryFn: async () => {
      const result = await apiGet<{
        session: Session
        otherPerson: PartialUser
        transcriptParagraphs: Paragraph[]
        summary: DefaultSummaryData
      }>(`/sessions/${id}`)
      return result
    },
    enabled: Boolean(id),
  })
}
